/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    
    // For getting baseurl
    function getBaseURL () {
        
       return location.protocol + "//" + location.hostname + 
          (location.port && ":" + location.port) + "/";
    }
    
    // For showing and hiding mobile menu
    function toggleMobileMenu() {
        
          var mobileNav = $('.mobile-menu');
          var navBar = $('.banner');
          var menuItems = $('.mobile-menu .menu-item');
          var body = $('body');
          var iconBarTop = $('.icon-bar.top');
          var iconBarMiddle = $('.icon-bar.middle');
          var iconBarBottom = $('.icon-bar.bottom');
          
            if(mobileNav.hasClass('visible')) {
                mobileNav.velocity('transition.slideDownOut', { 
                    duration: 300, 
                    complete: function(){
                        mobileNav.removeClass('visible');
                    } 
                });
                navBar.removeClass('dark');
                if(body.scrollTop() === 0) {
                    navBar.removeClass('affix');
                }
                body.removeClass('overflow-hidden');
              iconBarTop.removeClass('top-animate');
              iconBarMiddle.removeClass('middle-animate');
              iconBarBottom.removeClass('bottom-animate');
        
              $('body').off('scroll mousewheel touchmove', function(e) {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
              });
            } else {
                navBar.addClass('affix dark');
                mobileNav.velocity('transition.slideUpIn', { 
                    duration: 500, 
                    complete: function(){
                        mobileNav.addClass('visible');
                        menuItems.velocity('transition.slideLeftIn', {
                          duration: 300,
                          stagger: 50,
                      });
                    } 
                });
                body.addClass('overflow-hidden');
              iconBarTop.addClass('top-animate');
              iconBarMiddle.addClass('middle-animate');
              iconBarBottom.addClass('bottom-animate');
        
              $('body').on('scroll mousewheel touchmove');
            }
        }
    // For triggering image animations
    function initImageAnimations() {
        
        function showImages() {
            $(this.element).velocity('bounceLeftIn');
            this.destroy();
        }
        
        $('.el-reveal').each(function() {
            new Waypoint({
                element: this,
                handler: showImages,
                offset: '80%',
                group: 'images'
            });
        });
    }
    
    // For animating callout images
    function initCalloutImage() {

        var calloutImage = $('.callout-image');
        var calloutText = $('.callout-text');
        calloutImage.addClass('reveal');
        calloutText.addClass('reveal');
    }
    
    // For getting vars from url
    function getUrlParameters() {
        
        var pageUrl = decodeURIComponent(window.location.search.substring(1));
        var varsArray = pageUrl.split('&');
        var urlVars = {};
        
        $.each( varsArray, function( index, value ) {
            var key = value.split('=');
            urlVars[key[0]] = key[1];
        });
            
        return urlVars;
    }
    
    // For scrolling to section if in url vars
    function goToSection() {
        
        var urlVars = getUrlParameters();
        
        if('section' in urlVars) {
            var section = urlVars.section;
            $('.' + section).velocity('scroll', { duration: 500, offset: -100});
            return false;
        }
    }
    
    // For opening modal on url var
    function initModals() {
        
        var urlVars = getUrlParameters();
        
        if('modal' in urlVars) {
            var modalType = urlVars.modal;
            $('.modal.' + modalType).modal('show');
        }
    }
    
    /* ========================================================================
     * Forms & validation
     * ======================================================================== */
    
    // For validating emailaddress
    function validateEmail(value) {
        
        var expression = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;   
        var test = expression.test(value) ? true : false ;
        return test;
    }
    
    // For checking inputfields
    function checkFields(element) {
        
        var value = $(element).val();
        var elementType = $(element).attr('type');
        
        if(!value && $(element).prop('required')) {
            $(element).addClass('error');
        } else {
            $(element).removeClass('error');
        }
        
        switch(elementType) {
            case 'checkbox':
                if ($(element).is(":checked")) {
                    $(element).removeClass('error');
                } else {
                    $(element).addClass('error');
                }
                break;
            case 'email':
                if(validateEmail(value)) {
                    $(element).removeClass('error');
                } else {
                    $(element).addClass('error');
                }
                break;
        }
    }
    
    // For sending actual request
    function sendFormData(targetForm) {
        
        var datastr = {};
        
        $('.' + targetForm + ' .js-form-field').each(function(){
            var key = $(this).attr('name');
            var value = $(this).val();
            datastr[key] = value;
        });


        var recaptchaToken = 'g-recaptcha-response';

        if ( $('#'+recaptchaToken).size() ) {
            datastr[recaptchaToken] = $('#'+recaptchaToken).val();
        }

        if(typeof window.contact_form_ajax_post_url !== 'undefined') {
            $.ajax({
                type: "POST",
                url: window.contact_form_ajax_post_url,
                data: datastr,
                dataType: "json",
                cache: false,
                success: function(response) {
                    if(response.statuscode === 200) {
                        $('.' + targetForm + ' .js-form-field').val('').hide();
                        $('.' + targetForm + ' .js-send-form').hide();
                        $('.' + targetForm + '.success-notice').show();
                    } else {
                        $('.js-form-error.connection-error').show();
                    }
                }
            });
        } else {
            console.error('The ajax url for the contact form is not defined.');
        }
        
    }
    
    // For sending contact form
    function sendContactForm(targetForm) {
        
        var error = false;
            
        $('.' + targetForm + ' .js-form-field').each(function(){
            checkFields(this);
            if($(this).hasClass('error')) {
                error = true;
            }
        });
        
        if(!error) {
            $('.' + targetForm + ' .js-form-error').hide();
            //sendFormData(targetForm);
        } else {
            $('.' + targetForm + ' .js-form-error.fill-fields').show();
        }
    }

    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                
                //For showing the mobile menu
                $('.js-mobile-menu-toggle').click(function() {
                    /* toggleMobileMenu(); */
                });
                
                // For sending contact
                $('.js-send-form').click(function(e) {
                    e.preventDefault();
                    var targetForm = $(this).data('target-form');
                    sendContactForm(targetForm);
                });
                
                // For checking input fields
                $('.js-form-holder input, .js-form-modal input').blur(function() {
                    checkFields(this);
                });
                
                // For buttons with waypoint trigger	
                	$('.waypoint-trigger').click(function() {
                    var scrollTarget = $(this).attr('data-target');
                    $('.' + scrollTarget).velocity('scroll', { duration: 500, offset: -83});
                    return false;
                	});
                	
                	initModals();
                
                initCalloutImage();
            
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        'home': {
            init: function() {
                
                goToSection();

                // Init slider
                $('.customer-slider').slick({
                    infinite: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplaySpeed: 5000,
                    mobileFirst:true,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 6,
                                slidesToScroll: 6,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
                
                $('.mobile-nav .menu-item').click(function() {
                    toggleMobileMenu();
                });
            
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        page_template_template_landing: {
            init: function() {
                
                $('.mobile-nav .menu-item').click(function() {
                    toggleMobileMenu();
                });
                	
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        single: {
            init: function() {
                
                // For handling share windows
                $('.share-link').click(function(e) {
                    e.preventDefault();
                    window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + 
                    ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + 
                    ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                    return false;
                });
              
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
